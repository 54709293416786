/* eslint-disable no-param-reassign */
import React from 'react';

const Header: React.FC = () => {
  return (
    <div>
      <div
        style={{
          whiteSpace: 'nowrap',
          background: '#FFF',
          height: '100px',
          zoom: '100%',
        }}
      >
        <div style={{ display: 'inline' }}>
          <a href="#/pages/index.md">
            <img
              style={{ verticalAlign: 'middle' }}
              src="./images/topbar.png"
              alt="adaguc logo"
            />
          </a>
        </div>
        <div
          style={{
            display: 'inline-block',
            left: '45%',
            width: '55%',
            fontSize: '20px',
            color: '#006496',
            position: 'absolute',
            top: '35px',
            fontWeight: 'bold',
            whiteSpace: 'pre-line',
            background: 'rgba(255, 255, 255, 0.25)',
          }}
        >
          {/* <b
            style={{
              fontSize: '14px',
              color: '#006496',
              marginLeft: '0px',
              paddingLeft: '0px',
              wordWrap: 'normal',
            }}
          > */}
          Atmospheric data access for the geospatial user community
          {/* </b> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
