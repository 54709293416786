import React from 'react';

export interface ConfigType {
  FRONTEND_URL: string;
}

const exampleConfig: ConfigType = {
  FRONTEND_URL: '',
};

export const isValidConfig = (configObject: ConfigType): boolean =>
  Object.keys(exampleConfig).every(
    (key) => key in configObject && !!configObject[key],
  );

const loadConfig = async (): Promise<ConfigType> => {
  const res = await fetch('./config.json', { credentials: 'include' });
  const mod = (await res.json()) as ConfigType;
  return mod;
};

export const useConfig = (): [ConfigType] => {
  const [configObject, setConfigObject] = React.useState<ConfigType>(null);
  React.useEffect(() => {
    loadConfig()
      .then((result): void => {
        setConfigObject(result);
      })
      .catch(() => {
        throw new Error('Unable to load configuration file [config.json]');
      });
  }, []);
  return [configObject];
};
