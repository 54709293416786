/* eslint-disable @typescript-eslint/prefer-as-const */
const appStyles = {
  wrapper: {
    display: 'grid',
    gridGap: 0,
    gridTemplateRows: '100px 1fr 20px',
    gridTemplateColumns: '0 1fr',
    gridTemplateAreas:
      "'header header  header' 'content content content''footer footer footer'",
    backgroundColor: '#fff',
    color: '#444',
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    overflow: 'hidden',
    fontFamily: 'Roboto',
  },
  content: {
    gridArea: 'content',
    width: '100%',
    height: '100%',
    margin: '0px',
    padding: '0',
    overflowY: 'scroll' as 'scroll',
    overflowX: 'auto' as 'auto',
    backgroundColor: 'white',
    letterSpacing: '0.0075em',
    zIndex: 5,
    color: 'black',
    fontSize: '18px',
    lineHeight: '1.6em',
    justifyContent: 'center',
    // boxShadow:
    //   '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
  },
  header: {
    gridArea: 'header',
    backgroundColor: 'white',
    color: 'white',
    fontSize: '1.25rem',
    padding: '0px',
    zIndex: 6,
    margin: 0,
    top: 0,
    width: '100%',
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 1px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
  },
  footer: {
    gridArea: 'footer',
    backgroundColor: '#eeeeee',
    color: 'black',
    padding: '2px 2px 2px 10px',
    letterSpacing: '0.0075em',
    zIndex: 20,
    fontSize: '13px',
    lineHeight: '15px',
    fontStyle: 'normal',
    margin: '0',
    overflow: 'hidden',
    boxShadow:
      '0px -2px 4px -1px rgba(0, 0, 0, 0.2), 0px -4px 5px 0px rgba(0, 0, 0, 0.14), 0px -1px 10px 0px rgba(0, 0, 0, 0.12)',
  },
};
export default appStyles;
