/* eslint-disable no-param-reassign */
import React from 'react';

export interface TestComponentProps {
  url: string;
}
const TestComponent: React.FC<TestComponentProps> = ({
  url,
}: TestComponentProps) => {
  return <div>TEST:{url} OK</div>;
};

export default TestComponent;
