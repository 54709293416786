/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import React from 'react';
import { createRoot } from 'react-dom/client';

import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { ConfigType } from './loadConfig';
import MapWithRadar from './MapWithRadar';
import SimpleMapView, { SimpleMapViewProps } from './SimpleMapView';
import TestComponent from './TestComponent';

const replaceFunctions = [
  {
    search: 'images/',
    replace: `images/`,
  },
  {
    search: 'pages/',
    replace: `#/pages/`,
  },
];

interface MarkdownFromFileProps {
  mdFile: string;
  configObject: ConfigType;
}

const MarkdownFromFile: React.FC<MarkdownFromFileProps> = ({
  mdFile,
  configObject,
}: MarkdownFromFileProps) => {
  const [text, setText] = React.useState(`loading`);

  const updateText = (newText: string): void => {
    setText(newText);
    const elements = document.getElementsByClassName('react-component');
    for (let j = 0; j < elements.length; j += 1) {
      const element = elements[j];
      let attributes = {};
      try {
        attributes = JSON.parse(element.getAttribute('props'));
      } catch (e) {
        console.error(
          'Invalid json props given:',
          element.getAttribute('props'),
          e,
        );
      }
      const component = element.getAttribute('component');
      switch (component) {
        case 'TestComponent':
          createRoot(element).render(<TestComponent url="" {...attributes} />);
          // ReactDOM.render(
          //   React.createElement(TestComponent, attributes),
          //   element,
          // );
          break;
        case 'MapWithRadar':
          createRoot(element).render(<MapWithRadar {...attributes} />);
          // ReactDOM.render(
          //   React.createElement(MapWithRadar, attributes),
          //   element,
          // );
          break;
        case 'SimpleMapView':
          createRoot(element).render(
            <SimpleMapView {...(attributes as SimpleMapViewProps)} />,
          );
          // ReactDOM.render(
          //   React.createElement(SimpleMapView, attributes),
          //   element,
          // );
          break;
        // ReactDOM.render(
        //   React.createElement(SimpleMapView, (attributes)),
        //   element,
        // );
        default:
          element.innerHTML = `<div class='error'>Error: unknown component ${component}<div>`;
      }
    }
  };

  const updateContent = (): void => {
    const replacerReducer = (result: string, { search, replace }): string =>
      result.replace(new RegExp(search, 'g'), replace);
    const handleNotFound = (e): void => {
      console.error(e);
      setText('Not found');
      throw e;
    };
    let newURL =
      window.location.hash.length > 0
        ? configObject.FRONTEND_URL + window.location.hash.replace('#', '')
        : mdFile;
    // eslint-disable-next-line no-console
    newURL = newURL.replace('//', '/');
    console.log(`Fetching${newURL}`);

    const suffix = newURL
      .split('/')
      .reverse()[0]
      .split('.')
      .slice(0, -1)
      .join('.');

    document.title = suffix === 'index' ? 'Adaguc site' : `Adaguc - ${suffix}`;

    fetch(newURL)
      .then((response) =>
        response.ok ? response.text() : Promise.reject(new Error('-')),
      )
      .then((result) => replaceFunctions.reduce(replacerReducer, result))
      .then((result) => updateText(result))
      .catch(handleNotFound);
  };

  React.useEffect(() => {
    window.addEventListener('hashchange', updateContent);
    updateContent();
    return (): void => {
      window.removeEventListener('hashchange', updateContent);
    };
  });

  return typeof text === 'string' ? (
    <ReactMarkdown rehypePlugins={[rehypeRaw]}>{text}</ReactMarkdown>
  ) : (
    text
  );
};
export default MarkdownFromFile;
