/* eslint-disable react/style-prop-object */
import * as React from 'react';
import {
  MapView,
  MapViewLayer,
  MapViewLayerProps,
} from '@opengeoweb/webmap-react';
import { WMLayer, webmapUtils, LayerType } from '@opengeoweb/webmap';

import { ThemeWrapper } from '@opengeoweb/theme';

const { generateLayerId, generateMapId, getWMJSMapById } = webmapUtils;

export interface SimpleMapViewProps {
  layers: WMLayer[];
  srs: string | null;
  bbox: { left: number; bottom: number; right: number; top: number } | null;
}

const SimpleMapView: React.FC<SimpleMapViewProps> = ({
  layers,
  srs = null,
  bbox = null,
}: SimpleMapViewProps) => {
  if (!layers || layers.length === 0) {
    return <b>No layers configured</b>;
  }
  const mapId = generateMapId();
  const layersWithId = layers.map((layer) => {
    return { id: generateLayerId(), ...layer };
  });

  return (
    <ThemeWrapper>
      <MapView
        mapId={mapId}
        showLegend
        srs={srs || undefined}
        bbox={bbox || undefined}
      >
        {srs !== 'GFI:TIME_ELEVATION' && (
          <MapViewLayer
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...({
              id: generateLayerId(),
              name: 'arcGisSat',
              type: 'twms',
              layerType: LayerType.baseLayer,
            } as MapViewLayerProps)}
          />
        )}
        {layersWithId.map((layer) => {
          const layerId = layer.id;
          return (
            <MapViewLayer
              key={layerId}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...({
                service: layer.service,
                name: layer.name,
                format: 'image/png',
                style: layer.style || 'default',
                opacity: 1.0,
                enabled: true,
                id: layerId,
                layerType: LayerType.mapLayer,
                onLayerReady: (mapLayer: WMLayer): void => {
                  if (!bbox) {
                    mapLayer.zoomToLayer();
                    getWMJSMapById(mapId).zoomOut();
                  }
                },
                onLayerError: (e): void => {
                  // eslint-disable-next-line no-console
                  console.log('ERROR', e);
                },
              } as MapViewLayerProps)}
            />
          );
        })}
      </MapView>
    </ThemeWrapper>
  );
};

export default SimpleMapView;
