/* eslint-disable react/style-prop-object */
import * as React from 'react';
import {
  MapView,
  MapViewLayer,
  MapViewLayerProps,
} from '@opengeoweb/webmap-react';
import { WMLayer, WMJSMap, webmapUtils, LayerType } from '@opengeoweb/webmap';
import { ThemeWrapper } from '@opengeoweb/theme';

const { generateLayerId, generateMapId, getWMJSMapById } = webmapUtils;

const onRadarLayerReady = (layer: WMLayer, webMap: WMJSMap): void => {
  webMap.setAnimationDelay(100);

  if (layer) {
    const timeDim = layer.getDimension('time');

    if (timeDim) {
      const numTimeSteps = timeDim.size();
      const dates = [];
      for (let j = numTimeSteps - 24; j < numTimeSteps; j += 1) {
        dates.push({
          name: 'time',
          value: timeDim.getValueForIndex(j),
        });
      }
      webMap.stopAnimating();
      layer.zoomToLayer();
      webMap.draw(dates);
    }
  }
};

const MapWithRadar: React.FC = () => {
  const mapId = generateMapId();
  const layerId = generateLayerId();

  return (
    <ThemeWrapper>
      <MapView mapId={mapId}>
        <MapViewLayer
          id={generateLayerId()}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...({
            name: 'arcGisSat',
            type: 'twms',
            layerType: LayerType.baseLayer,
          } as unknown as MapViewLayerProps)}
        />
        <MapViewLayer
          id={layerId}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...({
            service: 'https://geoservices.knmi.nl/adagucserver?dataset=RADAR&',
            name: 'RAD_NL25_PCP_CM',
            format: 'image/png',
            style: 'precip-rainbow/nearest',
            enabled: true,
            layerType: LayerType.mapLayer,
            onLayerReady: (layer: WMLayer): void => {
              const webMap = getWMJSMapById(mapId);
              onRadarLayerReady(layer, webMap);
            },
            onLayerError: (e): void => {
              // eslint-disable-next-line no-console
              console.log('ERROR', e);
            },
          } as unknown as MapViewLayerProps)}
        />
        <MapViewLayer
          id={generateLayerId()}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...({
            service: 'https://geoservices.knmi.nl/wms?DATASET=OBS&SERVICE=WMS&',
            name: '10M/ta',
            format: 'image/png',
            style: 'default',
            opacity: '0.8',
            enabled: true,
            layerType: LayerType.mapLayer,
            onLayerReady: (layer: WMLayer): void => {
              const webMap = getWMJSMapById(mapId);
              onRadarLayerReady(layer, webMap);
            },
            onLayerError: (e): void => {
              // eslint-disable-next-line no-console
              console.log('ERROR', e);
            },
          } as unknown as MapViewLayerProps)}
        />
      </MapView>
    </ThemeWrapper>
  );
};

export default MapWithRadar;
