/* eslint-disable react/style-prop-object */
import * as React from 'react';
import { Alert } from '@mui/material';
import { useConfig, isValidConfig } from './loadConfig';
import MarkdownFromFile from './MarkdownFromFile';
import appStyles from './styles';
import Header from './Header';

require('./main.css');

const App: React.FC = () => {
  const [configObject] = useConfig();
  if (!configObject) {
    return null; // configuration is still loading, not showing anything to avoid flashing
  }

  if (!isValidConfig(configObject))
    return (
      <Alert severity="error">
        Configuration is not valid, not all urls are present or have a value
      </Alert>
    );

  return (
    <div style={appStyles.wrapper}>
      <div style={appStyles.header}>
        <Header />
      </div>
      <div style={appStyles.content}>
        <div
          style={{
            padding: '10px 20px',
            minWidth: '512px',
            maxWidth: '1200px',
            margin: 'auto',
          }}
        >
          <MarkdownFromFile
            configObject={configObject}
            mdFile="pages/index.md"
          />
        </div>
      </div>
      <div style={appStyles.footer}>Adaguc Website</div>
    </div>
  );
};

export default App;
